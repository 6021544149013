import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule, provideClientHydration } from '@angular/platform-browser';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { JwtInterceptor } from '../helpers/jwt.interceptor';
import { ToastrModule } from 'ngx-toastr';
import { AuthGuard } from 'src/guards/auth.guard';
import { UnAuthGuard } from 'src/guards/unauth.guard';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { NgSelectModule } from '@ng-select/ng-select';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AdminGuard } from 'src/guards/admin.guard';
import { NgxPaginationModule } from 'ngx-pagination';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';

import { registerLocaleData } from '@angular/common';
import localeIt from '@angular/common/locales/it';
import { IncompleteWizardGuard } from 'src/guards/incompleteWizard.guard';
import { RouterModule } from '@angular/router';
import { FourOFourComponent } from './errors/404/404.component';
import { NgxGpAutocompleteModule } from '@angular-magic/ngx-gp-autocomplete';
import { NgxStripeModule } from 'ngx-stripe';
import { NgxCurrencyInputMode, provideEnvironmentNgxCurrency } from 'ngx-currency';
import { ToAppComponent } from './to-app/to-app.component';

registerLocaleData(localeIt, 'it');

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    FourOFourComponent,
    ToAppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule,
    AppRoutingModule,
    HttpClientModule,
    ToastrModule.forRoot({
      preventDuplicates: false
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
      defaultLanguage: 'it'
    }),
    ModalModule.forRoot(),
    TabsModule.forRoot(),
    BsDatepickerModule.forRoot(),
    NgSelectModule,
    FontAwesomeModule,
    NgxPaginationModule,
    NgxGpAutocompleteModule.forRoot({
      loaderOptions: {
        apiKey: 'AIzaSyCd2P9UfS3nYY6KDR0zxuUZfCX9RHXyZH8',
        libraries: ['places']
      }
    }),
    NgxStripeModule.forRoot(),
    CarouselModule.forRoot(),
    TimepickerModule.forRoot()
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true
    },
    {
      provide: LOCALE_ID,
      useValue: 'it'
    },
    AdminGuard, AuthGuard, UnAuthGuard, IncompleteWizardGuard,
    provideEnvironmentNgxCurrency({
      align: "right",
      allowNegative: true,
      allowZero: true,
      decimal: ",",
      precision: 2,
      prefix: "",
      suffix: " €",
      thousands: ".",
      nullable: true,
      min: 0,
      inputMode: NgxCurrencyInputMode.Financial
    }),
    provideClientHydration()
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
