import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

import { Observable, of } from 'rxjs';

import { AlertService } from './alert.service';

export type HandleError = (operation?: string, result?: { result: any, error: any }) => (error: HttpErrorResponse) => Observable<{ result: any, error: any }>;

@Injectable({
  providedIn: 'root'
})
export class HttpErrorHandlerService {

  constructor(private alertService: AlertService) { }

  public createHandleError = (serviceName = '') =>
    (operation = 'operation', result = {} as { result: any, error: any }) => this.handleError(serviceName, operation, result);

  public handleError(serviceName = '', operation = 'operation', result = {} as { result: any, error: any }) {
    return (error: HttpErrorResponse): Observable<{ result: any, error: any }> => {
      if (error.error) {
        if (error.error.mustNotShow)
          return of({ result, error });

        // if (error.statusText !== "Bad Request")
        //   this.alertService.error(error.error.error);
        // else
        //   this.alertService.error("Error");
      }
      return of({ result, error });
    };
  }
}
