import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import jwt_decode from 'jwt-decode';
import { AuthService } from 'src/services/auth.service';
import { map } from 'rxjs/operators';

@Injectable()
export class AuthGuard {

  constructor(
    private router: Router,
    private authService: AuthService
  ) { }

  canActivate() {
    let userFromStorage = localStorage.getItem('currentUser') ? localStorage.getItem('currentUser') : null;

    if (userFromStorage == null) {
      this.router.navigate(['/auth']);
      return false;
    }

    let currentUser = JSON.parse(userFromStorage);

    if (!currentUser || !currentUser.token || !currentUser.user) {
      this.router.navigate(['/auth']);
      return false;
    }

    let tokenPayload: any = jwt_decode(currentUser.token);
    let nowTimestamp = new Date().getTime() / 1000;
    if (nowTimestamp >= tokenPayload.exp) {
      this.router.navigate(['/auth']);
      return false;
    }

    if(!currentUser.user.manager.completedWizard)
    {
      this.router.navigate(['/manager/onboarding']);
      return false;
    }

    return true;
  }
}