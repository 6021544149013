import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable()
export class UnAuthGuard {

  constructor(
    private router: Router,
  ) { }

  canActivate() {
    let userFromStorage = localStorage.getItem('currentUser') ? localStorage.getItem('currentUser') : null;

    if (userFromStorage != null) {
      this.router.navigate(['/manager']);
      return false;
    }

    return true;
  }
}