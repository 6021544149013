import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, Subject } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HandleError, HttpErrorHandlerService } from '../helpers/http-error-handler.service';

import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private APIEndpoint = environment.APIEndpoint;

  private authUrl = this.APIEndpoint + 'auth/';
  private managerUrl = this.APIEndpoint + 'managers/';

  private handleError: HandleError;


  private userRefreshSource = new Subject<boolean>();

  public shouldUserRefresh(): Observable<boolean> {
    return this.userRefreshSource.asObservable();
  }

  public userRefresh() {
    this.userRefreshSource.next(true);
  }

  constructor(private http: HttpClient, private httpErrorHandler: HttpErrorHandlerService) {
    this.handleError = this.httpErrorHandler.createHandleError('AuthService');
  }

  public login(email?: string, password?: string, rememberMe?: boolean): Observable<any> {
    return this.http.post<any>(this.managerUrl + 'login', { email, password, rememberMe })
      .pipe(
        catchError(this.handleError('login'))
      );
  }

  public logout() {
    localStorage.removeItem('currentUser');
  }

  public setUser(token: string, user: any) {
    localStorage.setItem('currentUser', JSON.stringify({ token, user }));
  }

  public getUser() {
    let userFromStorage = localStorage.getItem('currentUser') ? localStorage.getItem('currentUser') : null;
    if (userFromStorage == null) {
      return null;
    }

    let currentUser = JSON.parse(userFromStorage);
    return currentUser.user;
  }

  public confirmEmail(email: string, token: string): Observable<any> {
    return this.http.post<any>(this.authUrl + 'confirmEmail', { email, token })
      .pipe(
        catchError(this.handleError('confirmEmail'))
      );
  }

  public refreshUser(newToken: string, newUser: any) {
    let currentUserJSON: any = localStorage.getItem('currentUser') ? localStorage.getItem('currentUser') : null;
    let currentUser: any = currentUserJSON ? JSON.parse(currentUserJSON) : null;
    if (currentUser) {
      currentUser.token = newToken;
      currentUser.user = newUser;
      localStorage.setItem('currentUser', JSON.stringify(currentUser));
    }

    this.userRefresh();
  }

  public getUserFromWeb() {
    return this.http.post<any>(this.authUrl + 'renewToken', {})
      .pipe(
        catchError(this.handleError('getUserFromWeb'))
      );
  }

  public refreshUserFromWeb(){
    this.getUserFromWeb().subscribe();
  }

  public forgotPassword(email: string): Observable<any> {
    return this.http.post<any>(this.authUrl + 'forgotPassword', { email })
      .pipe(
        catchError(this.handleError('forgotPassword'))
      );
  }

  public resetPassword(email: string, token: string, password: string): Observable<any> {
    return this.http.post<any>(this.authUrl + 'resetPassword', { email, token, newPassword: password })
      .pipe(
        catchError(this.handleError('resetPassword'))
      );
  }
}
