import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse } from '@angular/common/http';
import { Observable, map } from 'rxjs';

import jwt_decode from 'jwt-decode';
import { Router } from '@angular/router';
import { AuthService } from 'src/services/auth.service';
import { isPlatformBrowser } from '@angular/common';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

  constructor(@Inject(PLATFORM_ID) private platformId: Object, private router: Router, private authService: AuthService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (isPlatformBrowser(this.platformId)) {
      let currentUserString = localStorage.getItem('currentUser');
      let currentUser: any = currentUserString ? JSON.parse(currentUserString) : null;

      if (currentUser && currentUser.token) {
        let tokenPayload: any = jwt_decode(currentUser.token);
        let nowTimestamp = new Date().getTime() / 1000;
        if (nowTimestamp >= tokenPayload.exp) {
          localStorage.removeItem('currentUser');
          this.router.navigate(['/auth']);
          location.reload();
        }
        else {
          request = request.clone({
            setHeaders: {
              Authorization: `${currentUser.token}`
            }
          });

        }
      }
      return next.handle(request).pipe(
        map((response: any) => {
          if (!(response instanceof HttpResponse))
            return response;

          const headers = response.headers;

          if (headers.has('Next-Token')) {
            let userFromStorage = localStorage.getItem('currentUser') ? localStorage.getItem('currentUser') : null;
            if (userFromStorage == null) {
              return null;
            }

            let newToken = headers.get('Next-Token');
            let newDecoded: any = jwt_decode(newToken!);
            let newUser = newDecoded.data;

            this.authService.refreshUser(newToken!, newUser);
          }
          return response;
        })
      );
    } else {
      return next.handle(request);
    }
  }
}