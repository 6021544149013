<div class="container-fluid">
  <div class="row">
    <div class="col-12 bg-white shadow-sm d-flex justify-content-between align-items-center">
      <img src="../../../assets/images/logos/logo_nero_orizzontale.png" height="112">
      <div class="me-4">
        <button class="btn btn-lg btn-tomato" routerLink="../auth">Login</button>
      </div>
    </div>
  </div>
</div>

<div class="container-fluid bg-golden">
  <div class="container" style="padding-top: 80px; padding-bottom: 80px;">
    <div class="row align-items-center text-center">
      <div class="col-12">
        <h1>
          <span class="underline-orange position-relative">
            <span class="position-relative" style="z-index:1!important;">404</span>
          </span> - pagina non trovata!
        </h1>

        <div class="t-subtitle">
          La pagina che stai cercando non esiste o è stata rimossa. <br>
          Torna alla <a class="link-tomato" href="/">Home</a>
        </div>

      </div>

    </div>
  </div>
</div>

<div class="container mt-5">
  <div class="row justify-content-center">
    <div class="col-12 text-center">
      <a href="https://www.iubenda.com/privacy-policy/27680388" class="iubenda-noiframe iubenda-noiframe link-tomato"
        title="Privacy Policy">Privacy
        Policy</a>
      <a href="https://www.iubenda.com/privacy-policy/27680388/cookie-policy"
        class="iubenda-noiframe iubenda-noiframe link-tomato ms-3" title="Cookie Policy">Cookie Policy</a>

      <a href="https://www.iubenda.com/termini-e-condizioni/27680388"
        class="iubenda-noiframe iubenda-noiframe link-tomato ms-3" title="Termini e Condizioni">Termini e
        Condizioni</a>
    </div>
  </div>
</div>