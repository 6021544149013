import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  constructor(
    private toastr: ToastrService,
  ) { }

  success(message: string) {
    this.toastr.success(message, "", {
      extendedTimeOut: 0,
      positionClass: 'toast-bottom-right',
      closeButton: true,
      progressBar: true,
      timeOut: 2500,
    });
  }

  successNoHide(message: string) {
    this.toastr.success(message, "", {
      extendedTimeOut: 0,
      positionClass: 'toast-bottom-right',
      closeButton: true,
      progressBar: true
    });
  }

  error(message: string) {
    this.toastr.error(message, "", {
      extendedTimeOut: 0,
      positionClass: 'toast-bottom-right',
      closeButton: true,
      progressBar: true,
      timeOut: 2500
    });
  }

  errorNoHide(message: string) {
    this.toastr.error(message, "", {
      extendedTimeOut: 0,
      positionClass: 'toast-bottom-right',
      closeButton: true,
      progressBar: true,
    });
  }
}