import { Component, OnInit } from '@angular/core';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons';

@Component({
  selector: 'app-to-app',
  templateUrl: './to-app.component.html',
  styleUrls: ['./to-app.component.scss']
})
export class ToAppComponent implements OnInit {

  faEnvelope = faEnvelope;

  public linkGoogle = "https://play.google.com/store/apps/details?id=com.enthemo.enthemo";
  public linkApple = "https://apps.apple.com/app/enthemo/id6450899960";

  constructor() { }

  ngOnInit(): void {
    switch(this.getMobileOperatingSystem()) {
      case "iOS":
        window.location.href = this.linkApple;
        break;
      case "Android":
        window.location.href = this.linkGoogle;
        break;
    }
  }

  public getMobileOperatingSystem() {
    var userAgent = navigator.userAgent;

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
      return "Windows Phone";
    }

    if (/android/i.test(userAgent)) {
      return "Android";
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent)) {
      return "iOS";
    }

    return "unknown";
  }
}
