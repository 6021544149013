<div class="container-fluid">
  <div class="row">
    <div class="col-12 bg-white shadow-sm d-flex justify-content-between align-items-center">
      <img src="../../../assets/images/logos/logo_nero_orizzontale.png" height="112">
      <div class="me-4">
        <button class="btn btn-lg btn-tomato" routerLink="/auth">Login</button>
      </div>
    </div>
  </div>
</div>

<div class="container-fluid bg-golden">
  <div class="container" style="padding-top: 80px; padding-bottom: 80px;">
    <div class="row align-items-center">
      <div class="col-12 col-lg-7 pe-5">
        <h1>Unisciti a
          <span class="underline-orange position-relative">
            <span class="position-relative" style="z-index:1!important;">Enthemo</span>
          </span>
        </h1>

        <div class="t-subtitle">
          Se non sei stato reindirizzato automaticamente allo Store corretto, selezionalo manualmente

          <div class="d-xl-flex mt-4">
            <a [href]="linkGoogle" target="_blank">
              <img src="../../../assets/images/stores/google-play-badge.png" alt="Google Play" height="42"
                class="pe-3">
            </a>
            <a [href]="linkApple" target="_blank">
              <img src="../../../assets/images/stores/app-store-badge.svg" alt="App Store" height="42">
            </a>
          </div>
        </div>

      </div>
      <div class="col-12 col-lg-5 ps-2 text-center">

        <img src="../../../assets/images/stocks/home-header-fused.png" class="img-fluid">

      </div>
    </div>
  </div>
</div>

<div class="container">
  <footer class="row py-5 mt-5 mb-3 border-top">
    <div class="col-12 col-lg-5 mb-3 text-center">
      <a href="/" class="d-flex justify-content-center mb-2 text-decoration-none">
        <img src="../../../assets/images/logos/logo.png" height="200">
      </a>
      <p class="t-section">&copy; 2024 Enthemo Group S.r.l.</p>
    </div>

    <div class="col-12 col-lg-2 mb-4 mb-lg-3 text-center">
      <h5>Note legali</h5>
      <ul class="nav flex-column">
        <li class="nav-item mb-2"> <a href="https://www.iubenda.com/privacy-policy/27680388"
            class="iubenda-noiframe iubenda-noiframe link-tomato" title="Privacy Policy">Privacy
            Policy</a></li>
        <li class="nav-item mb-2"> <a href="https://www.iubenda.com/privacy-policy/27680388/cookie-policy"
            class="iubenda-noiframe iubenda-noiframe link-tomato" title="Cookie Policy">Cookie Policy</a></li>
        <li class="nav-item mb-2"> <a href="https://www.iubenda.com/termini-e-condizioni/27680388"
            class="iubenda-noiframe iubenda-noiframe link-tomato" title="Termini e Condizioni">Termini e
            Condizioni</a></li>
      </ul>
    </div>

    <div class="col-12 col-lg-2 mb-4 mb-lg-3 text-center">
      <h5>Sede legale</h5>
      <p>
        Via delle Sirene, 11
        <br>91025 Marsala (TP)
      </p>

      <br>

      <h5>Partita IVA</h5>

      <p>IT02862410814</p>
    </div>

    <div class="col-12 col-lg-2 mb-4 mb-lg-3 text-center">
      <h5>Contatti</h5>
      <p>
        <fa-icon [icon]="faEnvelope"></fa-icon> <a href="mailto:info@enthemo.com"
          class="link-tomato ms-2">info&#64;enthemo.com</a>
      </p>

    </div>

  </footer>
</div>