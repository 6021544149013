import { Component, Inject, PLATFORM_ID } from '@angular/core';

import { TranslateService } from "@ngx-translate/core";
import { environment } from 'src/environments/environment';

import * as moment from 'moment';
import { isPlatformBrowser } from "@angular/common";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'enthemo';

  constructor(@Inject(PLATFORM_ID) private platformId: Object, private translate: TranslateService) {
    if (isPlatformBrowser(this.platformId)) {
      translate.addLangs(['it', 'us']);
      this.getAndSetLanguage();
    }
  }

  getAndSetLanguage() {
    let lang = localStorage.getItem('lang');
    if (lang) {
      this.translate.use(lang);
      moment.locale(lang);
    } else {
      this.translate.use('it');
      moment.locale('it');
    }


    if (!environment.production || environment.environment == 'staging') console.log(environment)
  }
}
